<template>
  <div class="user-data">
    <h2>用户数据查询</h2>

    <!-- 查询功能 -->
        <el-row :gutter="20">
          <el-col :span="8">
            <el-input
              v-model="searchQuery"
              placeholder="请输入要查询的用户信息"
              clearable
              @clear="fetchUserData"
            />
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="fetchUserData">查询</el-button>
          </el-col>
        </el-row>
    <el-divider></el-divider>

    <!-- 用户数据表格 -->
    <el-table :data="userList" border style="width: 100%">
      <el-table-column prop="registerDate" label="注册时间" />
      <el-table-column prop="nickname" label="用户昵称" />
      <el-table-column prop="inviteCode" label="用户邀请码" />
      <el-table-column prop="superior" label="用户上级" />
      <el-table-column prop="directReferral" label="直推人数" />
      <el-table-column prop="umbrellaPeople" label="伞下人数" />
      <el-table-column prop="svipCount" label="SVIP数量" />
      <el-table-column prop="balance" label="用户余额 (USDT)" />
      <el-table-column prop="tgid" label="TGID" />
      <el-table-column prop="country" label="国家" />
      <el-table-column prop="genCount" label="GEN数量" />
      <el-table-column prop="usdt" label="USDT" />
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: '', // 查询条件
      userList: [], // 用户数据列表
    };
  },
  methods: {
    // 查询用户数据
    fetchUserData() {
      // 模拟调用 API 查询用户数据，通常这里会发起 API 请求
      this.$message.info(`正在查询: ${this.searchQuery}`);

      // 示例数据（你可以替换为真实的 API 响应）
      this.userList = [
        {
          registerDate: '2024-10-01',
          nickname: '用户A',
          inviteCode: 'INV123',
          superior: '用户B',
          directReferral: 10,
          umbrellaPeople: 50,
          svipCount: 5,
          balance: 1000,
          tgid: 'TG001',
          country: '中国',
          genCount: 500,
          usdt: 100,
        },
        {
          registerDate: '2024-10-05',
          nickname: '用户C',
          inviteCode: 'INV456',
          superior: '用户D',
          directReferral: 5,
          umbrellaPeople: 20,
          svipCount: 2,
          balance: 500,
          tgid: 'TG002',
          country: '美国',
          genCount: 300,
          usdt: 200,
        },
      ];

      // 根据 searchQuery 过滤数据（示例过滤逻辑）
      if (this.searchQuery) {
        this.userList = this.userList.filter((user) =>
          user.nickname.includes(this.searchQuery)
        );
      }
    },
  },
};
</script>

<style scoped>
.user-data {
  padding: 20px;
}
.custom-input
{
      width: 300px;
      height: 40px;
}

h2 {
  margin-bottom: 20px;
}
</style>
