<template>
  <div class="base-data">
    <!-- 顶部显示今日日期 -->
    <el-row justify="center" class="date-display">
      <el-col :span="24">
        <h2>{{ currentDate }}</h2>
      </el-col>
    </el-row>

    <!-- 四个数据框 -->
    <el-row gutter="20" class="data-cards">
      <el-col :span="6">
        <el-card class="data-card" shadow="hover">
          <div class="card-content">
            <h3>今日发起提现金额</h3>
            <p>{{ todayRegisteredUsers }} USDT</p>
          </div>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card class="data-card" shadow="hover">
          <div class="card-content">
            <h3>今日提现成功金额</h3>
            <p>{{ totalRegisteredUsers }} USDT</p>
          </div>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card class="data-card" shadow="hover">
          <div class="card-content">
            <h3>累计全部提现金额</h3>
            <p>{{ activeUsers }} USDT</p>
          </div>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card class="data-card" shadow="hover">
          <div class="card-content">
            <h3>累计提现成功总金额</h3>
            <p>{{ totalBalance }} USDT</p>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentDate: new Date().toLocaleDateString(), // 当前日期
      todayRegisteredUsers: 100, // 示例数据：今日注册用户数
      totalRegisteredUsers: 10000, // 示例数据：全部注册用户数
      activeUsers: 2000, // 示例数据：活跃用户数
      totalBalance: 50000, // 示例数据：用户全部余额（USDT）
    };
  },
};
</script>

<style scoped>
.base-data {
  padding: 20px;
}

.date-display {
  text-align: center;
  margin-bottom: 20px;
}

.data-cards {
  margin-top: 20px;
}

.data-card {
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
}

.card-content h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.card-content p {
  font-size: 24px;
  font-weight: bold;
}

.el-card {
  background: white;
  border-radius: 15px; /* 圆角效果 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
