<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const scrollPosition = ref(0);
const showDialog = ref(false);
const company = ref('');
const name = ref('');
const phone = ref('');
const toastVisible = ref(false);
const toastMessage = ref('');


const handleScroll = () => {
    scrollPosition.value = window.scrollY; // 获取当前滚动位置
};

onMounted(() => {
    window.addEventListener('scroll', handleScroll); // 绑定滚动事件监听器
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll); // 移除滚动事件监听器
});

const handleSubmit = () => {
    console.log('Submitted info:', { company: company.value, name: name.value, phone: phone.value });
    toastMessage.value = 'Submitted successfully!';
    toastVisible.value = true;
    showDialog.value = false; // 关闭弹窗
    company.value = ''; // 清空输入框
    name.value = '';
    phone.value = '';

    // 设置定时器，3秒后隐藏 toast
    setTimeout(() => {
        toastVisible.value = false;
    }, 3000);
};

const scrollToModule = (moduleId) => {
    const element = document.getElementById(moduleId);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' }); // 添加平滑滚动
    }
};

const showAdvertisers = ref(false);
const showAdvertisersone = ref(false);
const showAdvertisersthree = ref(false);

function handleMouseOver() {
    showAdvertisers.value = true; // 鼠标移动到 ul 上时显示 div
    showAdvertisersone.value = false;
    showAdvertisersthree.value = false;
}

function handleMouseOut() {
    showAdvertisers.value = false; // 鼠标移出 ul 时隐藏 div
    showAdvertisersone.value = false;
    showAdvertisersthree.value = false;
}

function handleMouseOvers() {
    showAdvertisersone.value = true; // 鼠标移动到 ul 上时显示 div
    showAdvertisers.value = false;
    showAdvertisersthree.value = false;
}

// function handleMouseOverthree() {
//     showAdvertisersone.value = false; // 鼠标移动到 ul 上时显示 div
//     showAdvertisers.value = false;
//     showAdvertisersthree.value = true;
// }



</script>



<template>
    <div class="inmoel">
        <div class="logo">
            <div class="logos">
                <div class="left">
                    <img class="images" src="@/assets/logo_icon.png" alt="">
                    <img src="@/assets/logo_ad1.png" alt="">
                </div>
                <div class="rigth" @mouseleave="handleMouseOut">
                    <ul class="diandian">
                        <li @mouseover="handleMouseOver"   >
                            For Advertisers
                            <img v-if="showAdvertisers" src="@/assets/ic_arrow_down1.png" alt="">
                            <img v-else src="@/assets/ic_arrow_down.png" alt="">
                           
                            <div class="advertisersss" v-if="showAdvertisers">
                                <div class="advers" > 
                                    <div class="exchange" @click="scrollToModule('module1')">Advertiser</div>
                                    <div class="brandtbs">We help brands understand, identify, engage and acquire
                                        consumers
                             </div> 

                                </div>
                                <!-- <div class="exchange identify">DSP </div>
                                <div class="brandtbs understand">We help brands understand, identify, engage and acquire
                                    consumers</div> -->
                            </div>
                        </li>
                        <li @mouseover="handleMouseOvers">
                            For Developer
                            <img v-if="showAdvertisersone" src="@/assets/ic_arrow_down1.png" alt="">
                            <img v-else src="@/assets/ic_arrow_down.png" alt="">

                            <div class="advertisersssbtn" v-if="showAdvertisersone">
                                <div class="advers" >
                                    <div class="exchange" @click="scrollToModule('module2')">Developer</div>
                                    <div class="brandtbs">Provide commercial solutions for developers
                                    </div>

                                </div>

                            </div>
                        </li>
                        <li @click="scrollToModule('module3')">
                            Connect Us
                        </li>
                    </ul>

                </div>
            </div>
        </div>


        <div class="homes">
            <div class="homes">
                <div class="brandh">
                    <img class="image" src="@/assets/connextc.png" alt="">
                    <div class="connect">
                        <div class="customer">Connect with customers, Connect users</div>
                        <p class="brands">We help brands understand, identify, engage and acquire consumers</p>
                    </div>
                </div>
            </div>
        </div>


        <div class="enroll">
            <div class="singlecolumntile_tileHeading__8KfOx">
                <div class="boxs">
                    Pioneer of Mobile Advertising
                </div>

            </div>
            <div class="singlecolumntile_tileContent__Wo0kW">
                <div class="box">
                    <p class="uCenter">We have numerous collaborative moblle advertising platforms that enable
                        agents,brands, and publishers to establish genuine connections with their audiences and
                        unlocknewr
                        growth opportunities.
                    </p>
                </div>
            </div>
            <div class="publis">
                <div class="publistb">
                    <div class="left">
                        <img class="images" src="@/assets/img_2.png" alt="">
                    </div>
                    <div class="right">
                        <ul>
                            <li>
                                <img class="image" src="@/assets/ic_publishers.png" alt="">
                                <p>200,00+Publishers</p>
                            </li>
                            <li>
                                <img class="image" src="@/assets/ic_partners.png" alt="">
                                <p>1000+Advertising partners</p>
                            </li>
                            <li>
                                <img class="image" src="@/assets/ic_exposure.png" alt="">
                                <p>1000000+ Advertising exposure</p>
                            </li>
                            <li>
                                <img class="image" src="@/assets/ic_worldwide.png" alt="">
                                <p>1 Million independent users worldwide</p>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>



        <div class="mobile" id="module1">
            <div class="advertising">Mobile advertising and publishers</div>
            <div class="applaud">
                <div class="left">
                    <p class="xchange">Little Bear ADS </p>
                    <p class="xchange btns">Global Mobile Ad Exchange</p>
                    <p class="discover">
                        Discover the power of Little Bear ADS's DigitalAd Tech Piatform, providing a free ad serverand
                        effortless monetization solution. Ourplatform seamiessly connects top.tierpublishers with
                        premium marketers, enablingangaging axperiances for audiences worldwideacross all davices
                    </p>
                </div>
                <div class="right">
                    <img class="images" src="@/assets/img_3.png" alt="">
                </div>
            </div>
            <div class="applaud">
                <div class="right">
                    <img class="images" src="@/assets/img_4.png" alt="">
                </div>
                <div class="left">
                    <p class="xchange">Little Bear  ADS </p>
                    <p class="xchange btns">Global Mobile DSP</p>
                    <p class="discover">
                        Discover the powor of Little Bear ADS's DigitaAd Tech Platform, providing a frae ad serverand
                        effortless monetization solution.Ourplatform seamlessly connects top-tierpubliahers with premium
                        marketers. enablingengaging experiences for audiences worldwideacross all ceViCes
                    </p>
                </div>

            </div>
        </div>

<div class="mobile" id="module2">
        <div class="advertisings">
            <div class="monetization">Mobile advertising monetization</div>
            <div class="applaud">
                <div class="left">
                    <p class="xchange"> Game and Application developers </p>
                    <p class="discover">
                        Discower the power of Little Bear ADS's DigitalAd Tech Platform, providing a free ad serverand
                        effortless monetization solution. Ourplatform seamlessly connects top-tierpublishers with
                        premium marketers, enablingengging axperiences for audiencas woridwideacress all davicps
                    </p>
                </div>
                <div class="right">
                    <img class="images" src="@/assets/img_5.png" alt="">
                </div>
            </div>
        </div>
        </div>

        <div class="needs" id="module3">
            <div class="finds">
                <div class="left">
                    <img class="images" src="@/assets/img_6.png" alt="">
                </div>
                <div class="right">
                    <p class="solution">Let's find a solution that suits your needs.</p>
                    <p class="simple">It’s quick, simple and easy.</p>
                    <button class="connects" @click="showDialog = true">connect us</button>
                    <div v-if="showDialog" class="dialog-overlay">
                              <div class="dialog">
                                  <h2>Connect Us</h2>
                                  <input v-model="company" placeholder="Company" />
                                  <input v-model="name" placeholder="Name" />
                                  <input v-model="phone" placeholder="Phone Number" />
                                  <button @click="handleSubmit">Submit</button>
                                  <button @click="showDialog = false">Cancel</button>
                              </div>
                        </div>
                    <div v-if="toastVisible" class="toast">{{ toastMessage }}</div>
                </div>
            </div>
        </div>


        <div class="discovers">

            <div class="aboutUs">About Us</div>
            <div class="powers">
                <img class="powerss" src="@/assets/img_bg_7.png" alt="">
                <div class="effortlessmonetization">
                    Dlscover the power of  Little Bear ADS's Digital Ad Tech Platfom, providing a free ad server and
                    effortlessmonetization solutlon. Our platform seamlessly connects top-ter publishers with premium
                    marketers.enabling engaging experlences for audlences worldwide across all devices
                </div>

            </div>
            <div class="audlences">
                <img class="images" src="@/assets/img_7.png" alt="">
                <img class="aubTimages" src="@/assets/img_bg_8.png" alt="">
            </div>
        </div>


        <div class="brandTrust">
            <div class="trustones"> Brand trust</div>


            <div class="googel">
                <span class="arocss">
                    <img class="images" src="@/assets/img_9_logo1.png" alt="">
                </span>
                <span class="arocss divsecs">
                    <img class="images" src="@/assets/img_9_logo2.png" alt="">
                </span>
                <span class="arocss">
                    <img class="images" src="@/assets/img_9_logo3.png" alt="">
                </span>
            </div>
        </div>


        <div class="footers">
            <div class="compnys">
                <div class="left">
                    <img class="images" src="@/assets/logo_ad2.png" alt="">
                    <div class="facebooks">
                        Webpixels attempts to bring the bestdevelopment experience to designers and developers by
                        offering the tools needed forhaving a quick and solid start in most webprojects.
                    </div>
                    <div class="quicks">
                        <img class="images" src="@/assets/img_10_logo1.png" alt="">
                        <img class="images" src="@/assets/img_10_logo2.png" alt="">
                        <img class="images" src="@/assets/img_10_logo3.png" alt="">
                        <img class="images" src="@/assets/img_10_logo4.png" alt="">
                    </div>
                </div>
                <div class="right">
                    <div class="one">
                        <p class="account">Account</p>
                        <p>Profile</p>
                        <p>Settings</p>
                        <p>Billing</p>
                        <p>Notifications</p>
                    </div>
                    <div class="two">
                        <p class="account">About</p>
                        <p>Services</p>
                        <p>Pricing</p>
                        <p>Contact</p>
                        <p>Careers</p>
                    </div>
                    <div class="three">
                        <p class="account">Company</p>
                        <p>Community</p>
                        <p>Help center</p>
                        <p>Contact</p>
                        <p>Support</p>
                    </div>
                </div>
            </div>

        </div>
        <!-- text-indent: 2em; -->

    </div>
</template>

<style scoped lang="less">
.inmoel {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;

    .homes {
        width: 100vw;
        // height: 87.9vh;
        background-color: #eee;
        // margin-top: 7px;

        .brandh {
            position: relative;
            width: 100vw;
            // height: 87.9vh;

            .image {
                width: 100%;
                height: 100%;
            }
        }

        .connect {
            position: absolute;
            top: 35%;
            left: 15%;
            width: 40vw;
            color: #fff;

            .brands {
                // width: 524px;
                // height: 84px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 2vw;
                margin-top: 60px;
            }

            .customer {
                width: 50vw;
                // height: 10vh;
                font-family: LINE Seed Sans, LINE Seed Sans;
                font-weight: 800;
                font-size: 3vw;
            }
        }
    }

    .diandian{
        list-style: none; /* 去掉默认的点符号 */
        padding: 0;       /* 去掉默认的内边距 */
    }
    .enroll {
        width: 100vw;
        // height: 1000px;
        background: #2B2657;
        margin-top: -10px;
        padding-top: 160px;

        .publis {
            display: flex;
            justify-content: center;
            align-items: center;

            .publistb {
                display: flex;
                // justify-content: space-between;
                width: 55vw;
                padding: 40px 0;

                .right {
                    ul {

                        li {
                            display: flex;
                            align-items: center;
                            width: 22vw;
                            // padding: 10px 0;
                            padding-left: 10px;
                            background: linear-gradient(270deg, rgba(54, 74, 255, 0) 0%, rgba(115, 37, 217, 0.4) 98%);
                            border-radius: 20px 20px 20px 20px;
                            // background-color: pink;
                            margin-bottom: 2vh;

                            .image {
                                width: 3vw;
                                // height: 31px;
                            }

                            p {
                                // margin-top: 2px;
                                margin-left: 20px;
                                font-family: PingFang SC, PingFang SC;
                                font-weight: 600;
                                font-size: 1vw;
                                color: #FFFFFF;

                            }
                        }
                    }
                }

                .left {
                    width: 30vw;
                    height: 3\28vh;

                    .images {
                        width: 100%;
                        height: 100%;
                    }
                }


            }

        }

        .singlecolumntile_tileHeading__8KfOx {
            display: flex;
            justify-content: center;
            // margin-top: 100px;
            text-align: center;

            .boxs {
                width: 70vw;
                // height: 150px;
                font-size: 25px;
                font-family: LINE Seed Sans, LINE Seed Sans;
                font-weight: 800;
                font-size: 4vw;
                color: #fff;
            }
        }

        .singlecolumntile_tileContent__Wo0kW {
            display: flex;
            justify-content: center;

            .box {
                width: 55vw;
                // height: 150px;
                font-size: 25px;
                line-height: 40px;
            }

            .uCenter {
                text-indent: 2em;
                color: #A7BBF3;
                font-size: 24px;
            }

        }
    }

    .footers {
        width: 100vw;
        // height: 30vh;
        padding: 5vh 0;
        background: #000000;
        color: #FFFFFF;

        .compnys {
            display: flex;
            justify-content: center;


            .left {
                width: 30vw;

                // height: 30vh;
                .quicks {
                    margin-top: 2vh;
                    width: 30vw;
                    height: 4vh;

                    .images {
                        width: 4vw;
                        // height: 4vh;
                        margin-right: 1vw;
                    }
                }

                .images {
                    width: 15vw;
                }

                .facebooks {
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 1.4vw;
                    color: #A5AFC5;

                }
            }

            .right {
                list-style: none; 
                display: flex;
                justify-content: space-between;
                width: 30vw;
                // height: 30vh;
                margin-left: 2vw;
                font-size: 1.2vw;
                color: #A5AFC5;

                .account {
                    margin-top: -0.2vh;
                    font-family: LINE Seed Sans, LINE Seed Sans;
                    font-weight: bold;
                    font-size: 1.5vw;
                    color: #A5AFC5;
                }
            }
        }


    }

    .brandTrust {
        width: 100vw;
        padding-top: 100px;
        font-family: LINE Seed Sans, LINE Seed Sans;
        font-weight: 800;
        font-size: 2vw;
        background: #ECEFFF;
        color: #3785FF;
        text-align: center;
        padding-bottom: 40px;

        .trustones {
            text-align: center;
        }

        .googel {
            display: flex;
            justify-content: center;
            background: #ECEFFF;
            // padding: 50px 0;
            margin-top: 20px;
        }

        .arocss {
            width: 8vw;
            // height: 4vh;

            .images {
                width: 100%;
                // height: 100%;
            }

        }

        .divsecs {
            margin: 0 2vw;
        }

    }

    .discovers {
        width: 100vw;
        // height: 100vh;
        background: url('@/assets/img_us_bg.png');

        .powerss {
            position: absolute;
            top: -15%;
            left: 14%;
            width: 5vw;
            // height: 100px;
        }


        .aboutUs {
            display: flex;
            justify-content: center;
            padding-top: 8vh;
            font-family: LINE Seed Sans, LINE Seed Sans;
            font-weight: 800;
            font-size: 2vw;
            color: #fff;

           
        }


        .audlences {
            position: relative;
            display: flex;
            justify-content: center;
            margin-top: 3vh;

            .images {
                width: 60vw;
            }
            .aubTimages {
                position: absolute;
                top: 0%;
                right: 13%;
                width: 7vw;
                // height: 7vh;
            }
        }

        .powers {
            position: relative;
            width: 100vw;
            display: flex;
            justify-content: center;
            margin-top: 5vh;

            .effortlessmonetization {
                width: 60vw;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 1.3vw;
                color: #FFFFFF;
                text-align: center;
            }
        }
    }

    .needs {
        width: 100vw;
        // height: 15vh;
        // padding: 1vw 0;
        background: #5163DC;
        // margin-top: 10vh;

        .finds {
            display: flex;
            justify-content: center;

            .left {
                width: 18vw;
                // height: 15vh;

                .images {
                    width: 100%;
                    height: 100%;
                }
            }

            .right {
                width: 42vw;
                // height: 15vh;
                margin-top: 1vw;

                .solution {
                    font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
                    font-weight: 1000;
                    font-size: 1.9vw;
                    color: #FFFFFF;
                }

                .simple {
                    font-family: Alibaba PuHuiTi 2.0, Alibaba PuHuiTi 20;
                    font-weight: 1000;
                    font-size: 1.5vw;
                    color: rgba(253, 255, 147, 1);
                    margin-top: -2vw;
                }

                .connects {
                    width: 10vw;
                    height: 4vh;
                    background: #FFBC48;
                    border: none;
                    border-radius: 16px 16px 16px 16px;
                    font-family: LINE Seed Sans, LINE Seed Sans;
                    font-weight: bold;
                    font-size: 1vw;
                    color: #FFFFFF;
                }
            }
        }
    }

    .advertisings {
        width: 100vw;
        // height: 100vh;
        background: #2B2657;
        padding-bottom: 100px;

        .monetization {
            display: flex;
            justify-content: center;
            padding-top: 8vh;
            font-family: LINE Seed Sans, LINE Seed Sans;
            font-weight: 800;
            font-size: 2vw;
            color: #fff;
        }

        .applaud {
            display: flex;
            justify-content: center;
            margin-top: 120px;

            .left {

                width: 30vw;
                // height: 200px;

                .xchange {
                    font-family: LINE Seed Sans, LINE Seed Sans;
                    font-weight: bold;
                    font-size: 1.5vw;
                    color: #fff;
                }

                .btns {
                    margin-top: -1vh;
                }

                .discover {
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 1.31vw;
                    color: #A7BBF3;
                    ;
                }
            }

            .right {
                list-style: none; 
                width: 30vw;
                height: 3\28vh;

                .images {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .mobile {
        width: 100vw;
        // height: 100vh;

        background: #1D1841;
        padding-bottom: 0.1vh;

        .advertising {
            display: flex;
            justify-content: center;

            padding-top: 8vh;
            font-family: LINE Seed Sans, LINE Seed Sans;
            font-weight: 800;
            font-size: 2vw;
            color: #fff;
            margin-bottom: 60px;
        }

        .applaud {
            display: flex;
            justify-content: center;
            margin-bottom: 10vh;


            .left {
                position: relative;
                width: 30vw;
                // height: 200px;

                .xchange {
                    font-family: LINE Seed Sans, LINE Seed Sans;
                    font-weight: bold;
                    font-size: 1.5vw;
                    color: #fff;
                }

                .btns {
                    margin-top: -20px;
                }

                .discover {
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 1.31vw;
                    color: #A7BBF3;
                }
            }

            .right {
                width: 30vw;
                height: 3\28vh;

                .images {
                    width: 100%;
                    height: 100%;
                }
            }
        }

    }

    .logo {

        display: flex;
        width: 100vw;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;

        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .3);
        display: flex;
        left: 0;
        position: relative;
        
        top: 0;
        z-index: 999;

        .exchange {
            // margin-top: 40px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 1.6vw;
            // margin-bottom: 0.5vh;
        }

        .advers {
            display: flex;
            justify-content: space-between;
            align-items: self-start;
            margin-top: 60px;
            margin-bottom: 1vw;

        }

        .brandtbs {
            width: 24vw;
            // margin: -20px 0;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 1vw;
            color: #6f7c95;
            padding-bottom: 10px;
            // border-bottom: 1px solid #eee;
        }

        .identify {
            margin-top: 10px;
        }

        .understand {
            border-bottom: none;
        }

        .logos {
            display: flex;
            justify-content: space-between;
            // width: 100%;
            // height: 100px;
            padding: 20px 0;
            // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .08);

            .left {
                display: flex;
                align-items: center;
                margin-right: 10vw;
                width: 20vw;

                // height: 100%;
                .images {
                    width: 6vw;
                    margin-right: 20px;
                }
            }

            .rigth {

                width: 40vw;
                height: 100%;
                color: #6f7c95;
                font-size: 1.2vw;

                ul {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    li {
                        // width: 15vw;
                        margin-top: 10px;
                        position: relative;
                        color: #6F7C95;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 600;
                        // font-size: 18px;
                    }

                    .advertisersss {
                        position: absolute;
                        // top: 250%;
                        left: -150%;
                        width: 35vw;
                        // padding: 50px;
                        // height: 40vh;
                        padding: 1.5vh 2vw 1.5vh 3.5vw;
                        padding-bottom: 20px;
                        background-color: #fff;
                        border-radius: 0 0 30px 30px;
                        // margin-top: 100px;
                        z-index: 999;
                        color: #4A82ED;
                        /* 过渡效果 
                        /* 过渡效果 */
                    }

                    .advertisersssbtn {
                        position: absolute;
                        // top: 250%;
                        left: -200%;
                        width: 35vw;
                        // padding: 50px;
                        // height: 40vh;
                        padding: 0 2vw;
                        padding-bottom: 20px;
                        background-color: #fff;
                        border-radius: 0 0 30px 30px;
                        // margin-top: 100px;
                        z-index: 999;
                        color: #4A82ED;
                    }

                    .advertisersssbtns {
                        position: absolute;
                        // top: 250%;
                        left: -300%;
                        width: 35vw;
                        // padding: 50px;
                        // height: 40vh;
                        padding: 0 1.6vw;
                        padding-bottom: 20px;
                        background-color: #fff;
                        border-radius: 0 0 30px 30px;
                        // margin-top: 100px;
                        z-index: 999;
                        color: #4A82ED;
                    }

                    ul:hover .advertisersss:hover {
                        display: block;
                        /* 鼠标移动到 ul 上时显示 */
                        opacity: 1;
                        transition: opacity 0.3s ease;
                        /* 确保可见 */
                    }

                }
            }
        }
    }
}

.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
     z-index: 999;
}

.dialog {
    background: white;
    padding: 30px;
    border-radius: 5px;
    width: 400px;
    text-align: center;
}

input {
    display: block;
    margin: 15px auto;
    padding: 10px;
    width: 95%;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.toast {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 128, 0, 0.8);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    transition: opacity 0.5s ease;
    z-index: 1000; /* 确保 toast 在其他元素之上 */
}


</style>