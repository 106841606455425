<template>
  <el-container style="height: 200vh;">
    <!-- 顶部导航栏 -->
    <el-header style="background-color: #409EFF; color: white; text-align: center; font-size: 30px;display: flex; justify-content: center; align-items: center;">
      GEN后台管理
    </el-header>
     <!-- 退出按钮 -->
          <el-button 
            type="danger" 
            @click="handleLogout"
            style="position: absolute; right: 20px; top: 30px;"
          >
            退出
          </el-button>
    <el-container>
      <!-- 左侧菜单栏 -->
      <el-aside width="200px" style="background-color: #333;">
        <el-menu
          :default-active="activeMenu"
          @select="handleMenuSelect"
          background-color="#333"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <!-- 用户数据主菜单 -->
          <el-menu-item index="base-data">
            <i class="el-icon-s-data"></i>
            <span >用户数据</span>
          </el-menu-item>
        
            <el-menu-item index="users-data">
              <i class="el-icon-star-off"></i>
              <span>基础数据</span>
            </el-menu-item>
            <el-menu-item index="svip-data">
              <i class="el-icon-star-off"></i>
              <span>SVIP数据</span>
            </el-menu-item>
            <el-menu-item index="recharge-data">
              <i class="el-icon-s-finance"></i>
              <span>充值数据</span>
            </el-menu-item>
            <el-menu-item index="withdraw-data">
              <i class="el-icon-wallet"></i>
              <span>提现数据</span>
            </el-menu-item>
            <el-menu-item index="config-control">
              <i class="el-icon-setting"></i>
              <span>策略配置</span>
            </el-menu-item>
            <el-menu-item index="withdraw-check">
              <i class="el-icon-setting"></i>
              <span>提现审核</span>
            </el-menu-item>
        </el-menu>
      </el-aside>

      <!-- 右侧内容区域 -->
      <el-main style="background-color: #f5f5f5;">
        <!-- 动态展示路由内容 -->
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      activeMenu: 'base-data', // 默认激活的菜单项
    };
  },
  methods: {
    // 根据菜单选择切换路由
    handleMenuSelect(index) {
      this.activeMenu = index; // 更新激活菜单
      // 根据选中项动态设置路由页面
      this.$router.push({ path: `/dashboard/${index}` });
    },
       // 退出登录
    handleLogout() {
          this.$router.push({ path: '/' });
        }
  }
};
</script>

<style scoped>
.el-aside {
  background-color: #d3d3d3;
}

.el-header {
  background-color: #409EFF;
  color: white;
  height: 80px;
}

.el-menu-item,
.el-submenu__title {
  color: white;
}
</style>
