<template>
  <div class="withdrawal-review">
    <h2>提现审核</h2>

    <!-- 刷新按钮 -->
    <el-button type="primary" @click="refreshData">刷新</el-button>

    <!-- 提现记录表格 -->
    <el-table :data="withdrawalList" border style="width:100%; margin-top:15px;padding: 5px">
      <el-table-column prop="param1" label="UID" />
      <el-table-column prop="param2" label="用户余额 (USDT)" />
      <el-table-column prop="param3" label="TGID" />
      <el-table-column prop="param4" label="提现金额USDT"  />
      <el-table-column prop="param5" label="提现手续费"  />
      <el-table-column prop="param6" label="提现时间"   />
      
     <el-table-column label="操作" width="280">
       <template v-slot="scope">
         <div class="action-buttons">
           <el-button @click="viewDetails(scope.row)">查看</el-button>
           <el-button type="success" @click="approveWithdrawal(scope.row)">通过</el-button>
           <el-button type="danger" @click="rejectWithdrawal(scope.row)">拒绝</el-button>
         </div>
       </template>
     </el-table-column>
    </el-table>

    <!-- 确认通过弹窗 -->
    <el-dialog title="确认通过" v-model="isApproveDialogVisible" width="30%">
      <p>请确认是否通过此提现申请？</p>
      <el-input v-model="approvalNote" type="textarea" placeholder="请填写审核说明" />
      <template #footer>
        <el-button @click="isApproveDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmApprove">确认</el-button>
      </template>
    </el-dialog>

    <!-- 确认拒绝弹窗 -->
    <el-dialog title="确认拒绝" v-model="isRejectDialogVisible" width="30%">
      <p>请确认是否拒绝此提现申请？</p>
      <el-input v-model="rejectionNote" type="textarea" placeholder="请填写拒绝原因" />
      <template #footer>
        <el-button @click="isRejectDialogVisible = false">取消</el-button>
        <el-button type="danger" @click="confirmReject">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      withdrawalList: [
        { param1: '287', param2: '100', param3: 'xiaozhi', param4: '2', param5: '1', param6: '2024-10-25' },
        // 更多数据...
      ],
      isApproveDialogVisible: false,
      isRejectDialogVisible: false,
      approvalNote: '',
      rejectionNote: '',
      selectedRow: null,
    };
  },
  methods: {
    // 查看详情
    viewDetails(row) {
      this.$message.info(`查看详情：${row.param1}`);
    },
    // 通过提现申请
    approveWithdrawal(row) {
      this.selectedRow = row;
      this.isApproveDialogVisible = true;
    },
    // 拒绝提现申请
    rejectWithdrawal(row) {
      this.selectedRow = row;
      this.isRejectDialogVisible = true;
    },
    // 确认通过
    confirmApprove() {
      this.$message.success('提现已通过');
      this.isApproveDialogVisible = false;
      this.approvalNote = '';
    },
    // 确认拒绝
    confirmReject() {
      this.$message.error('提现已拒绝');
      this.isRejectDialogVisible = false;
      this.rejectionNote = '';
    },
    // 刷新数据方法
    refreshData() {
      // 模拟通过API获取新的提现记录
      this.$message.success('刷新成功');
      this.withdrawalList = [
        { param1: '287', param2: '100', param3: 'xiaozhi2', param4: '5', param5: '3', param6: '2024-10-25'  },
        // 更多数据...
      ];
    }
  }
};
</script>

<style scoped>
.withdrawal-review {
  padding: 20px;
}
.action-buttons {
  display: flex;
  gap: 5px; /* 按钮之间的间距 */
}


.action-buttons .el-button {
  flex-shrink: 0; /* 确保按钮不会缩小 */
}
h2 {
  margin-bottom: 20px;
}
</style>
