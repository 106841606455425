<template>
  <div class="config-control">
    <h2>策略配置</h2>

    <!-- 查询功能 -->
<!--    <el-row :gutter="15">
         <el-col :span="8">
          <el-input v-model="searchQuery" placeholder="查询" clearable @clear="fetchConfigurations"
                  class="config-input"/>
         </el-col>
        <el-col :span="6">
         <el-button type="primary" @click="fetchConfigurations">查询</el-button>
        </el-col>
    </el-row> -->

        <el-row :gutter="20">
          <el-col :span="8">
            <el-input
              v-model="searchQuery"
              placeholder="查询"
              clearable
              @clear="fetchConfigurations"
            />
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="fetchConfigurations">查询</el-button>
          </el-col>
        </el-row>
    <el-divider></el-divider>

    <!-- 新建配置按钮 -->
    <el-button type="primary" @click="openDialog">新建</el-button>

    <!-- 弹窗：新建配置表单 -->
    <el-dialog title="新建配置" v-model="isDialogVisible" width="50%" @close="resetForm">
      <el-form :model="newConfig" label-width="120px" ref="configForm">
        <el-form-item label="标题" required>
          <el-input v-model="newConfig.param1" placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="每天GEN数量" required>
          <el-input v-model="newConfig.param2" placeholder="请输入对应的数量" />
        </el-form-item>
        <el-form-item label="USDT金额" required>
          <el-input v-model="newConfig.param3" placeholder="请输入USDT" />
        </el-form-item>
        <el-form-item label="有效期" required>
          <el-input v-model="newConfig.param4" placeholder="请输入有效期" />
        </el-form-item>
        <el-form-item label="是否开启" required>
          <el-input v-model="newConfig.param5" placeholder="请输入是/否" />
        </el-form-item>
      </el-form>

      <!-- 弹窗底部操作按钮 -->
      <template #footer>
        <el-button @click="isDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitConfig">保存</el-button>
      </template>
    </el-dialog>

    <el-divider></el-divider>

    <!-- 配置列表 -->
    <h3>配置列表</h3>
    <el-table :data="configList" border>
      <el-table-column prop="param1" label="参数1" />
      <el-table-column prop="param2" label="参数2" />
      <el-table-column prop="param3" label="参数3" />
      <el-table-column prop="param4" label="参数4" />
      <el-table-column prop="param5" label="参数5" />
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: '', // 查询条件
      isDialogVisible: false, // 控制弹窗是否可见
      newConfig: {
        param1: '',
        param2: '',
        param3: '',
        param4: '',
        param5: '',
      },
      configList: [], // 配置列表
    };
  },
  methods: {
    // 打开弹窗
    openDialog() {
      this.isDialogVisible = true;
    },
    // 提交新配置
    submitConfig() {
      this.$refs.configForm.validate((valid) => {
        if (valid) {
          this.configList.push({ ...this.newConfig }); // 添加到配置列表
          this.isDialogVisible = false; // 关闭弹窗
          this.resetForm(); // 重置表单
          this.$message.success('配置保存成功');
        }
      });
    },
    // 重置表单
    resetForm() {
      this.newConfig = {
        param1: '',
        param2: '',
        param3: '',
        param4: '',
        param5: '',
      };
    },
    // 查询配置
    fetchConfigurations() {
      // 在这里你可以根据 searchQuery 调用 API 来获取数据
      // 这里只是模拟查询
      this.$message.info(`查询配置: ${this.searchQuery}`);
    },
  },
};
</script>

<style scoped>
.config-control {
  padding: 20px;
}
.config-input{
    width: 300px;
    height: 40px;
}
h2, h3 {
  margin-bottom: 20px;
}
</style>
