import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '../views/UserLogin.vue'; 
import DashboardLayout from '../components/DashboardLayout.vue'; // 新布局组件
import BaseData from '../views/BaseData.vue';  // 基础数据页面
import SVIPData from '../views/SVIPData.vue';  // SVIP数据页面
import RechargeData from '../views/RechargeData.vue';  // 充值数据页面
import WithdrawData from '../views/WithdrawData.vue';  // 提现数据页面
import ConfigControl from '../views/ConfigControl.vue';  // 配置控制页面
import UserData from '../views/UserData.vue';  
import WithdrawCheck from '../views/WithdrawCheck.vue';  
import Homepage from '../components/HomePage.vue'; 

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: UserLogin
  },
  {
    path: '/',
    name: 'homepage',
    component: Homepage
  },
   {
      path: '/dashboard',
      component: DashboardLayout,
      children: [
        { path: 'users-data', component: UserData },
        { path: 'base-data', component: BaseData },
        { path: 'svip-data', component: SVIPData },
        { path: 'recharge-data', component: RechargeData },
        { path: 'withdraw-data', component: WithdrawData },
        { path: 'config-control', component: ConfigControl },
        { path: 'withdraw-check', component: WithdrawCheck}
      ]
    }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
